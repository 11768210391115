

import { NavbarTopComponent } from './components/navbar-top/navbar-top.cmp';
import { ToraxHttp } from './utils/custom-http.svc';
import { ViewChild, AfterViewInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from './services';
import { User } from './_models';
import { smoothlyMenu } from './app.helpers';
import { ServicesData } from './services/services.data';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavItem } from './components/menu-list-item/nav-item';

declare var jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  userName = '';
  userRoles: string[];


  navItems: NavItem[] = [
    { route: 'dashboard', displayName: 'פעילויות ובקרה', iconName: 'fa-th-large', roles: 'dashboard' },  //, exactActive: true
    { route: 'contacts', displayName: 'אנשי קשר', iconName: 'fa-address-book-o', roles: 'contacts' },
    {
      displayName: 'רשימות', iconName: 'fa-bars', children: [
         { route: 'commitments', displayName: 'הסכמים/התחייבויות', roles: 'commitments' },
        { route: 'paymentsPlans', displayName: 'תוכניות תשלום', roles: 'paymentsPlans' },
        { route: 'payments', displayName: 'תשלומים', roles: 'payments' },
        { route: 'payMethods', displayName: 'אמצעי תשלום', roles: 'payMethods' },
        { route: 'assistances', displayName: 'תמיכות', roles: 'assistances' },
        // { route: 'completeHoldings', displayName: 'החזקת בית המדרש', roles: 'Holdings' },
        // { route: 'shtibels', displayName: 'שטיבלך', roles: 'shtibels' }
      ]
    },
    //{ route: 'ivrAssignmentUserTasks', displayName: 'משימות תחזוקה', iconName: 'fa-wrench', roles: 'IvrAssignmentUserTasks' },
    // {
    //     displayName: 'התחייבויות', iconName: 'fa-money', children: [
    //         { route: '/reports/commitments/new', displayName: 'חדשות' },
    //         { route: '/reports/commitments/uncompleted', displayName: 'לטיפול' }
    //     ]
    // },
    // {
    //   displayName: 'כיבודים ועליות', iconName: 'fa fa-gift', children: [
    //     { route: 'honorsSets', displayName: 'שבתות-אירועים', roles: 'honorsSets' },
    //     { route: 'honors', displayName: 'רשימת כיבודים', roles: 'honors' },
    //     { route: 'honorTypes', displayName: 'הגדרת סוגי כיבודים', roles: 'honorTypes' },
    //   ]
    // },
    // {
    //   displayName: 'סטנציה', iconName: 'fa fa-university', children: [
    //     { route: 'registrations', displayName: 'רשימות', roles: 'registrations' },
    //     { route: 'registrations/groupRegistrations', displayName: 'רשימות מקובץ לאנשים', roles: 'groupRegistrations' },
    //     { route: 'registrationEvents', displayName: 'אירועים', roles: 'registrationEvents' }
    //   ]
    // },
    { route: 'ivrDonationDetails',  displayName: 'תרומות טלפוניות', iconName: 'fa fa-phone', roles: 'ivrDonationDetails' },
    {
      displayName: 'ניהול', iconName: 'fa-cog', children: [
        // { route: 'reportscenter', displayName: 'מרכז דוחו"ת', roles: 'ReportsCenter' },
        // { route: 'campaigns', displayName: 'קמפיינים', roles: 'Campaign' },
     //   { route: 'lotteries', displayName: 'כרטיסי הגרלה', roles: 'Lottery' },
     { route: 'donationCategories',  displayName: 'קטגוריות תרומות',   roles: 'donationCategories'},
     { route: 'users', displayName: 'משתמשים', roles: 'SystemAdministrator' },
     // { route: 'groups', displayName: 'הגדרת קבוצות', roles: 'groups' },
        // { route: 'processTypes', displayName: 'סוגי תהליכים', roles: 'processTypes' },
        { route: 'letterSettings', displayName: 'תבניות מכתבים', roles: 'letterSettings' },
        { route: 'commitmentReasons', displayName: 'סיבות התחייבות', roles: 'commitmentReasons' },
        { route: 'vouchersOrganizations', displayName: 'ארגוני שוברים', roles: 'vouchersOrganizations' },
        { route: 'collectionBuilders', displayName: 'יחידות סליקה', roles: 'collectionBuilders' },
        { route: 'transactions', displayName: 'טרנזקציות-אשראי', roles: 'transactions' },
        { route: 'cities', displayName: 'רשימת ערים', roles: 'cities' },
        { route: 'terms', displayName: 'מסופים', roles: 'terms' },
        { route: 'countries', displayName: 'שיוך מסופים למדינות', roles: 'countries' },
        // { route: 'collectionControl', displayName: 'בקרת גביה', roles: 'collectionControl' },
        // { route: 'states', displayName: 'מחוזות' },
      ]
    },
  //  { route: 'calendar', displayName: 'לוח שנה', iconName: 'fa fa-calendar', roles: 'SystemAdministrator' },
  ];

  title = 'עשה טוב';
  selected: any;
  subSelected: any;
  currentUserSubscription: Subscription;
  imgsrc;

  constructor(
    public router: Router,
    public titleService: Title,
    private authenticationService: AuthenticationService,
    public location: Location,
    public servicesData: ServicesData,
    private toraxHttp: ToraxHttp,
    private cdRef: ChangeDetectorRef, private observer: BreakpointObserver,
  ) {
    let dUrl = document.URL;
    if (dUrl.indexOf('10.0.0.75') > -1 || dUrl.indexOf('shmuel-site') > - 1 || dUrl.indexOf('localhost') > - 1 || dUrl.indexOf('127.0.0.1') > - 1) {
      this.testModeStyle = "solid 50px red";
    } else {
      this.testModeStyle = "none";
    }
    if (dUrl.indexOf('screenIndex') > -1) {
      this.screenMode = 'screenIndex';
    } else {
      this.screenMode = '';
    }
  }
  screenMode: string = '';
  testModeStyle: string = "none";

  @ViewChild("navBarTop") navBarTop: NavbarTopComponent;
  isMobile: boolean = false;
  topNavMargin = '200px';
  ngAfterViewInit() {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      this.topNavMargin = '0px';
      if (res.matches && this.sidenav) {
        this.sidenav.mode = 'over';
        this.sidenav.close();
      } else if (this.sidenav) {
        this.sidenav.mode = 'side';
        this.sidenav.open();
      }
    });
    this.cdRef.detectChanges();
  }



  get currentUser(): User {
    if (this.authenticationService) {
      return this.authenticationService.currentUserValue;
    }
  }


  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => {
      if (x) {
        this.userName = `${x.firstName} ${x.lastName}`;
        this.userRoles = x.roles;
      }
    });
    const userAgent = window.navigator.userAgent;
    if (userAgent.indexOf("Android") > -1) {
      this.isMobile = true;
    }

    this.router.events.subscribe((route) => {
      if (route instanceof RoutesRecognized) {
        let titleResult = '';

        let r: ActivatedRouteSnapshot | null = route.state.root;
        while (r) {
          if (r.data && r.data.title) {
            titleResult = r.data.title;
          }
          r = r.firstChild;
        }
        this.title = titleResult;

        this.titleService.setTitle(`עשה טוב | ${titleResult}`);
      }
    });


    this.authenticationService.currentUser.subscribe(u => {
      if (u) {
        this.isLoggedIn().subscribe(x => {
          if (!x && this.location.path().indexOf("/login") == -1) {
            this.authenticationService.logoutMe();
          } else if (x) {
            console.log("xxxxxx else", x);
          }
        }
        )
      }
    });
  }

  select(item) {
    this.selected = (this.selected === item ? null : item);
  }
  subSelect(item) {
    this.subSelected = (this.subSelected === item ? null : item);
  }
  isActive(item) {
    return this.selected === item;
  }


  itemCollapsed(e) {
    console.log(e);
  }


  @HostListener("keydown.enter", ['$event'])
  enterClick() {
    let curElement = (<any>event).target;

    let hasOtherElement = false;
    if (!curElement.attributes.hasOwnProperty('enter-tab')) {
      curElement.setAttribute('enter-tab', 0);
      hasOtherElement = true;  //למקרה שנמצא במשבצת מחוץ לסדר, ראה בהמשך ניתוק מהרשימה בחזרה
    }

    let closestForm = curElement.closest('form');
    if (closestForm) {
      let elements = closestForm.querySelectorAll('[enter-tab]:not(:disabled)');
      if (hasOtherElement) {
        curElement.removeAttribute('enter-tab');
      }

      if (elements && elements.length <= 1) {
        //  event.preventDefault();
        return;
      } else {
        let elementsArr = Array.from(elements);
        let index = elementsArr.indexOf(curElement);
        if ((elementsArr.length - 1) == index) {
          //  event.preventDefault();
          return;
        } else {
          let nextElement: any = elementsArr[index + 1];
          let jqNextElement = jQuery(nextElement);
          let isFocusable = jqNextElement.is(':focusable')
          if (!isFocusable) {
            nextElement = nextElement.querySelector('input:not(:disabled)');
          }
          if (nextElement) {
            nextElement.focus();
            event.preventDefault();
          } else {
            return;
          }

        }
      }
    }
  }


  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    this.authenticationService.resetTimer();
  }

  @HostListener('window:keydown', ['$event'])
  @HostListener('window:keypress', ['$event'])
  setSearchFocus(event) {
    if (event.ctrlKey && event.keyCode == 70) {
      //Block CTRL + F event
      if (this.navBarTop.searcAc.txtSearch)
        (<any>this.navBarTop.searcAc.txtSearch).nativeElement.focus();

      event.preventDefault();
    }
  }

  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  //כרגע לא חייב לעבור דרך ה-APP רק כדי שבהמשך נוכל להוסיף תמונת צילום התקלה
  sendSupportEmail() {
    let address = 'r0527145566@gmail.com';
    var subject = `בקשת תמיכה - מאת ${this.currentUser.firstName} ${this.currentUser.lastName}`;
    let mail = document.createElement("a");
    mail.target = "_blank";
    let body = "פרט כאן את תוכן התקלה וצרף צילום מסך...%0D%0A%0D%0Aבברכה%0A" + this.currentUser.firstName + "%0D%0A%0D%0A========================%0Aמידע טכני (לא למחוק)%0A" + this.location.path() + "%0D%0A%0D%0A";
    var win = window.document.open('', '_blank', 'emailWindow');
    win.location.href = 'mailto:' + address + '?subject=' + subject + '&body=' + body;
  }

  reLoadAllLists() {
    this.servicesData.loadAllLists();
  }

  ngOnDestroy() {
    this.currentUserSubscription.unsubscribe();
  }

  backClicked() {
    this.location.back();
  }

  isLoggedIn() {
    return this.toraxHttp.get(`/isLoggedIn`);
  }

  logoutBtn() {
    let isconfirm = true;
    let loginUrl = "login"
    // let returnUrl = "";
    let returnUrl = "dashboard";
    // const userAgent = window.navigator.userAgent;
    // if (userAgent.indexOf("Android") > -1 && event && event.target) {
    //   //let src = (<any>event.target).id || (<any>event.target).parentElement.id;

    //   isconfirm = confirm("לאפס שם משתמש וסיסמה?");
    //   if (isconfirm) {
    //     loginUrl = "login-mobile";
    //     this.authenticationService.logoutMe(loginUrl, returnUrl);
    //   }
    // }
    // else {
      this.authenticationService.logoutMe(loginUrl, returnUrl);
    //}
  }

}
